import { showToast, getError } from "~lib/helpers";

export type State<T> = [error?: Error, data?: T];

const handlePromise = async <T>(
  promise: () => Promise<T>,
  toast?: { success?: string; errorMsg?: boolean | string }
): Promise<State<T>> =>
  promise()
    .then((result) => {
      if (toast?.success) {
        showToast(toast?.success, "success");
      }

      const res: State<T> = [undefined, result];
      return res;
    })
    .catch((error: Error) => {
      if (toast?.errorMsg) {
        const msg =
          typeof toast?.errorMsg === "string"
            ? toast?.errorMsg
            : getError(error, "graphQL");

        showToast(msg, "error");
      }
      return [error, undefined];
    });

export default handlePromise;
