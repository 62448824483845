import { EventQuery, MembershipQuery } from '~graphql/sdk';

export const useSeatedMultibuy = (
  promotions:
    | EventQuery['event']['multiBuyPromotions']
    | MembershipQuery['membership']['multiBuyPromotions'],
  seats: any[],
) => {
  const activePromotions = promotions
    // @ts-ignore
    ?.reduce((acc, curr) => {
      const getType = curr?.getTicketType || curr?.getMembershipType;
      const buyType = curr?.buyTicketType || curr?.buyMembershipType;

      if (buyType && getType) {
        const buySeats = seats?.filter(
          /** @ts-ignore */
          ({ ticketType, membershipType }) =>
            (ticketType?.value ||
              /** @ts-ignore */
              ticketType?.id ||
              membershipType?.id) === buyType?.id,
        );

        const getSeats = seats?.filter(
          /** @ts-ignore */
          ({ ticketType, membershipType }) =>
            (ticketType?.value ||
              /** @ts-ignore */
              ticketType?.id ||
              membershipType?.id) === getType?.id,
        );

        const buyTotal = buySeats?.length;
        const buySeatQty = Math.floor(buyTotal / curr.buyQuantity);

        const getTotal = getSeats?.length;

        const giftedQty =
          buySeatQty * curr.getQuantity <= getTotal
            ? buySeatQty * curr.getQuantity
            : getTotal;

        if (getSeats && buySeats && buySeats?.length >= curr?.buyQuantity) {
          const activePromo = [
            ...buySeats.map((buy) => ({
              promotion: curr,
              ...buy,
              type: 'buying',
            })),
            ...getSeats?.slice(0, giftedQty).map((get) => ({
              promotion: curr,
              ...get,
              type: 'getting',
            })),
          ];

          return [
            ...acc,
            {
              ...curr,
              seats: activePromo,
              isActive: getSeats?.length > 0,
            },
          ];
        }
      }

      return [
        ...acc,
        {
          ...curr,
          isActive: false,
        },
      ];
    }, []);

  /** @note we'll return the first promotion that is active **/
  return {
    activePromotions:
      activePromotions?.find((promo) => promo?.isActive)?.seats ?? [],
  };
};
