import { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";

import { TabItem } from "~components";
import { FormStateContext } from "~context";

export const useTabs = (
  tabs: TabItem[],
  { pathname, as }: { pathname: string; as?: string }
) => {
  const router = useRouter();

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const initialIndex = tabs.findIndex(
    (t) => t.key === (router?.query?.tab || tabs[0].key)
  );

  const [currentTab, setTab] = useState(
    tabs?.[initialIndex] ? initialIndex : 0
  );
  const [nextTab, setNextTab] = useState(null);

  const { isDirty, setIsDirty } = useContext(FormStateContext);

  useEffect(() => {
    if (
      router?.query?.tab !== tabs[currentTab]?.key &&
      !(currentTab === 0 && !router?.query?.tab)
    ) {
      void router.push(
        {
          pathname,
          query: { tab: tabs[currentTab].key },
        },
        as && {
          pathname: as,
          query: { tab: tabs[currentTab].key },
        }
      );
    }

    setNextTab(null);
  }, [currentTab]);

  const alert = {
    header: "You have unsaved changes",
    description:
      "Leaving this page will cause all changes to be lost. Are you sure you want to leave this page?",
    okButtonText: "Discard changes",
    isOpen: isAlertOpen,
    close: () => setIsAlertOpen(false),
    onOk: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setIsDirty(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setTab(nextTab);
    },
  };

  const onTabChange = (tab) => {
    if (router?.query?.tab === tabs[tab]?.key) return;

    if (isDirty) {
      setNextTab(tab);
      setIsAlertOpen(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setTab(tab);
    }
  };

  return {
    onTabChange,
    currentTab: tabs?.[currentTab] ? currentTab : 0,
    alert,
  };
};
