import useSWR from "swr";
import { useSDK } from "~hooks";

export const useReleases = (eventId: string) => {
  const sdk = useSDK();

  const { data, ...methods } = useSWR(
    eventId ? ["releases", eventId] : null,
    async (_: string, eventId: string) =>
      sdk.releases({ event: eventId }).then((res) => res.releases)
  );

  return { data, ...methods };
};
