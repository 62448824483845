import { Flex, PrimaryButton, Text } from "flicket-ui";
import React, { useState } from "react";
import { Modal } from "~components";
import { OrderQuery } from "~graphql/sdk";
import { XeroInvoiceButton } from "./XeroInvoiceButton";

export const useXeroWarningModal = (order: OrderQuery["order"]) => {
  const [open, setOpen] = useState(false);

  const withXeroWarning = (action: () => void) => {
    return () => {
      if (order?.xeroInvoiceId) {
        setOpen(true);
      }
      action();
    };
  };

  const XeroWarningModal = () => (
    <>
      {order?.xeroInvoiceId ? (
        <Modal
          header="Xero Integration Warning"
          isOpen={open}
          close={() => setOpen(false)}
        >
          <Text paddingBottom={4}>
            Flicket does not currently support Xero invoice synchronization for
            edited orders. Please ensure you update the corresponding Xero
            invoice
          </Text>
          <Flex flexDir={"row"} float="right">
            <XeroInvoiceButton order={order} />
            <PrimaryButton paddingY={"1/4"} onClick={() => setOpen(false)}>
              Continue
            </PrimaryButton>
          </Flex>
        </Modal>
      ) : null}
    </>
  );

  return {
    XeroWarningModal,
    setOpen,
    withXeroWarning,
  };
};
