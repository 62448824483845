import ReactTooltip from "react-tooltip";
import { Icon } from "~components";

export const TooltipIcon = ({
  description,
  variant = "info",
}: {
  description: string;
  variant: "info" | "warning";
}) => (
  <>
    <Icon
      data-tip={description}
      icon={variant === "warning" ? "warning-circle" : "info"}
      ml={1}
      fontSize={3}
      mr={1}
      color={variant === "warning" ? "warning" : undefined}
    />
    <ReactTooltip
      effect="solid"
      textColor={"black"}
      borderColor="black"
      backgroundColor="lightgray"
      multiline={true}
    />
  </>
);
