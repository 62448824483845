import { Box } from "flicket-ui";
import {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { components, GroupProps } from "react-select";
import { Editor, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor, useEditor } from "slate-react";
import styled, { css, useTheme } from "styled-components";
import { CustomFields } from "~features/broadcast/customFields.enum";
import { useSDK } from "~hooks";
import { exhaustiveGuard } from "~lib";
import { IconName } from "../Icon/Icon";
import { ModalBase } from "../Modal";
import { Select } from "../Select";
import { insertDivider } from "./Divider";
import { fileSelectHandler } from "./Image";
import { insertButton } from "./InsertButton";
import {
  InsertButtonOrLinkOnSuccessOptions,
  InsertModalContent,
  SuggestedLinkType,
} from "./InsertModal";
import { insertLink } from "./Link";
import useScreenSize from "~hooks/useScreenSize";
import { BroadcastTransactionalType } from "~graphql/sdk";

export type RichTextDropdownOption = {
  label: string;
  value: string;
  icon: IconName;
};

export type GroupInsertOptions = {
  options: RichTextDropdownOption[];
};

export const transactionalInsertOptions = (
  transactionalType: BroadcastTransactionalType = BroadcastTransactionalType.Event
): GroupInsertOptions[] => [
  {
    options: [
      { label: "Banner image", value: "banner", icon: "banner-image-insert" },
      { label: "Image", value: "image", icon: "image" },
      { label: "Divider", value: "divider", icon: "divider" },
      { label: "Link", value: "link", icon: "link" },
    ],
  },
  {
    options:
      transactionalType === BroadcastTransactionalType.Event
        ? [
            {
              label: "Link to their tickets",
              value: "link:access-your-tickets",
              icon: "link",
            },
            {
              label: "Link to event page",
              value: "link:event-information",
              icon: "link",
            },
          ]
        : [
            {
              label: "Link to membership renewal",
              value: "link:membership-renewal",
              icon: "link",
            },
          ],
  },
  {
    options: [
      {
        label: "First name",
        value: CustomFields.FIRST_NAME,
        icon: "text-insert",
      },
      {
        label: "Last name",
        value: CustomFields.LAST_NAME,
        icon: "text-insert",
      },
    ],
  },
];

export const marketingInsertOptions: GroupInsertOptions[] = [
  {
    options: [
      { label: "Banner image", value: "banner", icon: "banner-image-insert" },
      { label: "Image", value: "image", icon: "image" },
      { label: "Divider", value: "divider", icon: "divider" },
      { label: "Link", value: "link", icon: "link" },
    ],
  },
  {
    options: [
      {
        label: "Link to event page",
        value: "link:event-information",
        icon: "link",
      },
      {
        label: "Link to ticketing page",
        value: "link:event-tickets",
        icon: "link",
      },
      {
        label: "Link to registration form",
        value: "link:event-registration",
        icon: "link",
      },
    ],
  },
  {
    options: [
      {
        label: "First name",
        value: CustomFields.FIRST_NAME,
        icon: "text-insert",
      },
      {
        label: "Last name",
        value: CustomFields.LAST_NAME,
        icon: "text-insert",
      },
    ],
  },
];

export const CustomFieldsOptions: RichTextDropdownOption[] = [
  { label: "First name", value: CustomFields.FIRST_NAME, icon: "text-insert" },
  { label: "Last name", value: CustomFields.LAST_NAME, icon: "text-insert" },
];

export const withFields = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "field" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "field" ? true : isVoid(element);
  };

  return editor;
};

const insertField = (editor: ReactEditor, content: string) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const field = { type: "field", content, children: [{ text: "" }] };
  Transforms.insertNodes(editor, field, {
    at: blurSelection,
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const StyledBox = styled(Box)<{ isFirst: boolean }>`
  position: relative;

  ${(props) =>
    !props.isFirst &&
    css`
      &::before {
        content: "";
        background: ${(props) => props.theme.colors.N200};
        position: absolute;
        left: 10%;
        top: 0;
        height: 1px;
        width: 80%;
      }
    `}
`;

export const RichtextEditorSelect: FC<{
  insertOptions?: GroupInsertOptions[];
  selectEvents?: boolean;
}> = ({
  insertOptions = transactionalInsertOptions(),
  selectEvents = false,
}) => {
  const editor = useEditor();
  const sdk = useSDK();
  const theme = useTheme();
  const isMobile = useScreenSize().isPhone;
  const [isOpen, setIsOpen] = useState(false);
  const [suggestedLink, setSuggestedLink] = useState<SuggestedLinkType>(
    undefined
  );
  const [isBanner, setIsBanner] = useState(false);
  const [, setSelectedText] = useState<string>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { Group } = components;

  const RichTextGroup = ({
    ...props
  }: GroupProps<RichTextDropdownOption, false>) => {
    // this is hard coded
    // props.options[0].value will use the value of the first option group
    // remove the divider for the first group
    let isFirst = false;
    if (props.options[0].value === "image") {
      isFirst = true;
    }
    return (
      <StyledBox isFirst={isFirst}>
        <Group {...props} />
      </StyledBox>
    );
  };

  useEffect(() => {
    if (isOpen) {
      const blurSelection = (editor.blurSelection as any) as Range;
      if (blurSelection) {
        const isCollapsed = Range.isCollapsed(blurSelection);
        if (!isCollapsed) {
          setSelectedText(Editor.string(editor, blurSelection));
        }
      }
    } else {
      setSelectedText(undefined);
    }
  }, [isOpen]);

  const defaultContent = ((): string | undefined => {
    if (!suggestedLink) return undefined;

    switch (suggestedLink) {
      case "access-your-tickets":
        return "Access your tickets";
      case "event-information":
        return "Event information";
      case "event-registration":
        return "Register now";
      case "event-tickets":
        return "Event tickets";
      case "membership-renewal":
        return "Renew your membership";
      default:
        exhaustiveGuard(suggestedLink);
    }
  })();

  return (
    <>
      <Select
        menuPortalTarget={document.body}
        options={insertOptions}
        onChange={(value: string) => {
          if (value === "divider") {
            insertDivider(editor);
          } else if (value === "image") {
            setIsBanner(false);
            fileInputRef.current.click();
          } else if (value === "banner") {
            setIsBanner(true);
            fileInputRef.current.click();
          } else if (value.startsWith("link")) {
            setIsOpen(true);
            setSuggestedLink(value.split(":")[1] as SuggestedLinkType);
          } else {
            insertField(editor, value);
          }
        }}
        styles={{
          group: (baseStyle: CSSProperties): CSSProperties => ({
            ...baseStyle,
            paddingTop: "4px",
            paddingBottom: "4px",
          }),
          control: (baseStyle: CSSProperties): CSSProperties => ({
            ...baseStyle,
            maxWidth: `${theme.space[15]}px`,
            height: `${theme.space[3]}px`,
            minHeight: `${theme.space[3]}px!important`,
            border: "none",
            alignContent: "center",
            boxShadow: "none!important",
          }),
          container: (baseStyle: CSSProperties): CSSProperties => ({
            ...baseStyle,
            width: isMobile ? "100px" : "auto",
            alignSelf: "flex-start",
          }),
          menu: (baseStyle: CSSProperties): CSSProperties => ({
            ...baseStyle,
            width: isMobile ? "200px" : "260px",
            marginTop: "12px",
            right: isMobile ? "-60px" : "-60px",
          }),
        }}
        flex={1}
        isSearchable={false}
        value={{ label: "Insert", value: "" }}
        components={{ Group: RichTextGroup }}
      />
      <ModalBase isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          setIsOpen={setIsOpen}
          isEmail
          defaultValues={{
            selectEvents,
            content: defaultContent,
            suggestedLink,
          }}
          onSuccess={(options: InsertButtonOrLinkOnSuccessOptions) => {
            const {
              type,
              color,
              content,
              suggestedLink,
              url,
              eventId,
              releaseId,
            } = options;
            if (type === "button") {
              insertButton(
                editor,
                content,
                color,
                url,
                suggestedLink,
                eventId,
                releaseId
              );
            } else {
              insertLink(editor, {
                url,
                content,
                suggestedLink,
                eventId,
                releaseId,
              });
            }
          }}
        />
      </ModalBase>
      <input
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        id="upload-image"
        type="file"
        onChange={(e) => {
          fileSelectHandler(e, editor, sdk, isBanner).catch(console.error);
        }}
        ref={fileInputRef}
      />
    </>
  );
};

export const StyledCustomFieldElement = styled.span`
  box-sizing: border-box;
  background: ${(p) => p.theme.colors.N100};
  mix-blend-mode: multiply;
  border: 1px solid ${(p) => p.theme.colors.N300};
  border-radius: 4px;
  padding: 0 4px;
`;

export const CustomFieldElement = ({
  content,
  children,
}: {
  content: string;
  children: ReactNode;
}) => (
  <StyledCustomFieldElement contentEditable={false}>
    {children}
    {CustomFieldsOptions.find((option) => option.value === content)?.label}
  </StyledCustomFieldElement>
);
