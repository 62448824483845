import React, { useState, useEffect, ChangeEvent } from "react";

import {
  Box,
  Flex,
  Price,
  Text,
  Divider,
  Checkbox,
  formatPrice,
  Stack,
  PrimaryButton,
  SecondaryButton,
  TransparentButton,
} from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { orderBy, isEmpty } from "lodash";

import { Input, Icon, Select } from "~components";
import { useSDK } from "~hooks";
import { handlePromise, showToast, getError } from "~lib";
import {
  TicketStatus,
  RefundGateway,
  PaymentProvider,
  OrderQuery,
} from "~graphql/sdk";
import { PriceInput } from "../common/PriceInput";
import { mutate as swrMutate } from "swr";

type RefundsModalContentProps = {
  order: OrderQuery["order"];
  mutate: typeof swrMutate;
  setIsOpen: (isOpen: boolean) => void;
};

const refundGatewayOptions = [
  { label: "Cash", value: RefundGateway.Cash },
  { label: "Credit card", value: RefundGateway.CreditCard },
  { label: "Eftpos", value: RefundGateway.Eftpos },
  { label: "Invoice", value: RefundGateway.Invoice },
  { label: "Other", value: RefundGateway.Other },
];

const getDefaultGateway = (gateway: PaymentProvider) => {
  const defaultGateway = refundGatewayOptions[0].value;
  if (
    [
      PaymentProvider.Windcave,
      PaymentProvider.Stripe,
      PaymentProvider.Laybuy,
    ].includes(gateway)
  ) {
    return refundGatewayOptions[1].value;
  } else if (gateway === PaymentProvider.Eftpos) {
    return refundGatewayOptions[2].value;
  } else if (gateway === PaymentProvider.Invoice) {
    return refundGatewayOptions[3].value;
  } else if (gateway === PaymentProvider.Other) {
    return refundGatewayOptions[4].value;
  }

  return defaultGateway;
};

export const RefundsModalContent = ({
  order,
  mutate,
  setIsOpen,
}: RefundsModalContentProps) => {
  const [showReleaseTickets, setShowReleaseTickets] = useState(false);
  const [isHandlingRefund, setIsHandlingRefund] = useState(false);
  const [refundAmount, setRefundAmount] = useState<number>(undefined);
  const [refundNotes, setRefundNotes] = useState<string>(undefined);
  const [seatsToRelease, setSeatsToRelease] = useState<
    OrderQuery["order"]["tickets"]
  >([]);
  const [isManual, setIsManual] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [refundGateway, setRefundGateway] = useState(
    refundGatewayOptions[0]?.value
  );

  const sdk = useSDK();

  useEffect(() => {
    if (order.paymentGateway) {
      setRefundGateway(getDefaultGateway(order.paymentGateway));
    }
  }, [order.paymentGateway]);

  const toggleReleasedSeats = (release: boolean) =>
    release
      ? setSeatsToRelease(
          order?.tickets?.filter((ticket) =>
            [TicketStatus.Active].includes(ticket.status)
          )
        )
      : setSeatsToRelease([]);

  const toggleReleasedSeat = (
    release: boolean,
    currentTicket: OrderQuery["order"]["tickets"][number]
  ) => {
    let newSeats = seatsToRelease?.filter(
      (ticket) => ticket.id !== currentTicket.id
    );

    if (release) {
      newSeats = [...newSeats, currentTicket];
    }

    setSeatsToRelease(newSeats);
  };

  const releaseSeats = async () => {
    const [error, data] = await handlePromise(async () =>
      sdk.releaseSeats({
        input: {
          orderId: order.id,
          notes: refundNotes,
          ticketIds: seatsToRelease?.map(({ id }) => id),
        },
      })
    );

    if (error || !data.releaseSeats) {
      showToast(
        error ? getError(error, "graphQL") : "Something went wrong!",
        "error"
      );

      return setIsHandlingRefund(false);
    }

    showToast("Seats have been released", "success");

    setIsOpen(false);

    setIsHandlingRefund(false);

    return mutate(undefined, true);
  };

  const handleRefund = async () => {
    setIsHandlingRefund(true);

    if (
      refundAmount >
      order?.total - (order?.refundedAmount ?? 0) - (order?.usedCredits ?? 0)
    ) {
      showToast("Refund amount cannot exceed the order total", "error");

      return setIsHandlingRefund(false);
    }

    if (isEmpty(refundNotes)) {
      showToast("Refund notes can not be empty", "error");
      return setIsHandlingRefund(false);
    }

    if (!refundAmount || refundAmount <= 0) {
      if (seatsToRelease.length) {
        return releaseSeats();
      } else {
        showToast("Refund amount can not be 0", "error");
        return setIsHandlingRefund(false);
      }
    }

    const [error, data] = await handlePromise(async () =>
      sdk.createRefund({
        input: {
          orderId: order.id,
          amount: refundAmount,
          notes: refundNotes,
          isManual,
          sendEmail,
          ...(seatsToRelease.length > 0 && {
            ticketIds: seatsToRelease?.map(({ id }) => id),
          }),
        },
      })
    );

    if (error || !data.createRefund) {
      showToast(
        error ? getError(error, "graphQL") : "Something went wrong!",
        "error"
      );
      return setIsHandlingRefund(false);
    }

    showToast("Amount has been refunded", "success");

    setIsOpen(false);
    setIsHandlingRefund(false);

    return mutate(undefined, true);
  };

  return (
    <>
      <Box pb={9}>
        {order?.tickets?.some(
          ({ status }) => status === TicketStatus.Active
        ) && (
          <Flex flex={1} justifyContent="space-between">
            <Checkbox
              label="Release tickets"
              checked={seatsToRelease?.length > 0}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                toggleReleasedSeats(e.target.checked)
              }
            />
            <TransparentButton
              onClick={() => setShowReleaseTickets(!showReleaseTickets)}
              flex={1}
              justifyContent="flex-end"
            >
              {seatsToRelease?.length > 0 && (
                <Text fontSize={2} fontWeight="heavy">
                  ({seatsToRelease.length} ticket
                  {seatsToRelease.length > 1 && "s"} selected)
                </Text>
              )}
              <Icon
                icon="chevron-down"
                ml="1/4"
                css={`
                  transform: rotate(${showReleaseTickets ? 180 : 0}deg);
                  transition: transform ease-in-out 0.3s;
                `}
              />
            </TransparentButton>
          </Flex>
        )}
        <AnimatePresence>
          {showReleaseTickets && (
            <motion.div
              initial={{
                height: 0,
                opacity: 0,
              }}
              animate={{
                height: "auto",
                opacity: 1,
              }}
              exit={{
                height: 0,
                opacity: 0,
              }}
            >
              <Divider my={2} />
              <Stack flexDir="column" space={1}>
                {orderBy(
                  order?.tickets,
                  ["lineItem.type", "lineItem.name"],
                  ["desc", "asc"]
                )
                  ?.filter((s) => s.status === TicketStatus.Active)
                  .map((ticket) => (
                    <Flex
                      flex={1}
                      justifyContent="space-between"
                      key={ticket.id}
                    >
                      <Checkbox
                        label={`${ticket?.lineItem.name} | (#${ticket?.ticketNumber})`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          toggleReleasedSeat(e.target.checked, ticket)
                        }
                        cursor="pointer"
                        disabled={ticket.status !== TicketStatus.Active}
                        checked={Boolean(
                          seatsToRelease?.find(
                            (releaseTicket) => releaseTicket.id === ticket.id
                          )
                        )}
                      />
                      <Price price={ticket.lineItem.price} />
                    </Flex>
                  ))}
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>

        <Divider my={2} />

        {order?.status != "Hold" ? (
          <>
            <Select
              label="Method"
              onChange={(val: RefundGateway) => setRefundGateway(val)}
              defaultValue={refundGateway}
              options={refundGatewayOptions}
              mb={2}
            />

            <PriceInput
              label="Refund amount"
              value={refundAmount}
              placeholder={`max amount ${formatPrice(
                order?.total - (order?.refundedAmount ?? 0)
              )}`}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              onChange={(e: any) => setRefundAmount(e.target.value)}
            />
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Text fontSize={3} lineHeight="medium" color="N600" mt={1}>
                The refund amount cannot exceed total order amount:{" "}
                {formatPrice(order?.total - (order?.refundedAmount ?? 0))}
              </Text>
              <Checkbox
                label="Manual refund"
                checked={isManual}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setIsManual(e.target.checked)
                }
              />
              <Checkbox
                label="Send email"
                checked={sendEmail}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSendEmail(e.target.checked)
                }
              />
            </Flex>
          </>
        ) : null}

        <Input
          inputAs="textarea"
          value={refundNotes}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setRefundNotes(e.currentTarget.value)
          }
          label="Refund notes"
          placeholder="Refund notes"
          mt={2}
        />
        <Divider mt={2} mb={-5 as any} />
      </Box>
      <Flex justifyContent="flex-end">
        <SecondaryButton onClick={() => setIsOpen(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => void handleRefund().catch((e) => console.error(e))}
          ml={2}
          isLoading={isHandlingRefund}
        >
          {refundAmount > 0
            ? seatsToRelease.length > 0
              ? "Refund and release seats"
              : "Refund"
            : seatsToRelease.length > 0
            ? "Release seats"
            : "Refund"}
        </PrimaryButton>
      </Flex>
    </>
  );
};
