import React, { FC, ReactNode, useState, useEffect, useMemo } from "react";

import {
  Box,
  Flex,
  Price,
  SecondaryButton,
  Text,
  Divider,
  Checkbox,
  formatPrice,
} from "flicket-ui";
import { orderBy } from "lodash";
import styled from "styled-components";

import {
  useOrganization,
  usePermissions,
  useSeatedMultibuy,
  useMultiBuy,
} from "~hooks";
import {
  Ticket,
  ShippingOption,
  LineItemType,
  OrderStatus,
  DiscountType,
  VenueSeating,
  TicketStatus,
  FeeType,
  GetReferralCampaignByOrderIdQuery,
  GetReferralRewardByOrderIdQuery,
  OrderQuery,
  LineItem,
  OrderType,
  TransferFeeType,
} from "~graphql/sdk";
import { calculateDiscount } from "~lib/helpers/finance";

import { ChangeSeatsModalContent } from "./ChangeSeatsModalContent";
import { EditModalContent } from "./edit/EditModalContent";
import { RefundsModalContent } from "./RefundsModalContent";
import { TicketFees } from "./TicketFees";
import { TransferModal } from "./transfer/Modal";
import { useXeroWarningModal } from "./useXeroWarningModal";
import { mutate as swrMutate } from "swr";
import {
  getPointsDisplayLabel,
  getPointsQuantityFromOrder,
} from "~features/points/helpers";
import { Modal } from "~components";
import { useQuery } from "~hooks/useQuery";
import { TransferredMembershipTicketsForOrderDocument } from "~graphql/typed-document-nodes";
import { TicketCover } from "./TicketCover";

const Title = styled(Text).attrs({
  fontSize: 3,
  fontWeight: "extraBold",
})``;

const formatDeliveryMethod = (deliveryMethod: ShippingOption) => {
  switch (deliveryMethod) {
    case ShippingOption.Eticket:
      return "E-ticket";
    case ShippingOption.PrintedTicketAtVenue:
      return "Printed ticket at venue";
    case ShippingOption.PrintedTicketByCourier:
      return "Printed ticket by courier";
    case ShippingOption.PrintedTicketByCourierRural:
      return "Printed ticket by courier - rural";
    case ShippingOption.PrintedTicketByMail:
      return "Printed ticket by mail";
    default:
      return "-";
  }
};

export const formatSeatLocation = (
  seatZone: Ticket["seatZone"],
  seatSection: Ticket["seatSection"],
  seatLabel: Ticket["seatLabel"]
) => {
  const split = seatLabel?.split("-");
  let label = "";

  if (split?.length > 2) {
    label = `Row ${split[1].trim()}, Seat ${split[2].trim()}`;
  } else if (split?.length === 2) {
    label = `Seat ${split[1].trim()}`;
  }

  return `${seatZone}${seatSection ? `, ${seatSection}` : ""}${
    label ? `, ${label}` : ""
  }`;
};

interface OrderItemProps {
  title?: string;
  isEditing: boolean;
  subtitle?: string;
  promo?: ReactNode;
  numReleasedTickets?: number | undefined;
  numTransferredTickets?: number | undefined;
  numTransferPendingTickets?: number | undefined;
  onChange?: (e: any) => void;
  referral?: string;
  numMembershipEvents?: number;
}

export const OrderItem: FC<OrderItemProps> = ({
  title,
  subtitle,
  promo,
  isEditing,
  onChange,
  numReleasedTickets: hasReleasedTickets = 0,
  numTransferredTickets: hasTransferredTickets = 0,
  numTransferPendingTickets: hasTransferPendingTickets = 0,
  numMembershipEvents,
  referral,
}) => {
  return (
    <Flex alignItems="flex-start">
      {isEditing && <Checkbox onChange={onChange} mr="6/4" />}
      <Box>
        {title && <Text lineHeight="normal">{title}</Text>}
        <Flex alignItems="baseline">
          <Text fontSize={1} lineHeight="high">
            {subtitle}
          </Text>
          {promo && (
            <Text
              fontWeight="demiBold"
              fontSize={1}
              lineHeight="high"
              color="N600"
              ml={1}
            >
              {promo}
            </Text>
          )}
          {referral && (
            <Text
              fontWeight="demiBold"
              fontSize={1}
              lineHeight="high"
              color="N600"
              ml={1}
            >
              {referral}
            </Text>
          )}
        </Flex>
        {hasReleasedTickets > 0 && (
          <Box w="fit-content">
            <Flex
              borderRadius="full"
              bg="N200"
              p="1/2"
              pr={1}
              alignItems="center"
              justifyContent="center"
              mt="1/4"
            >
              <Flex
                borderRadius="full"
                width={16}
                height={16}
                bg="warning50"
                mr="1/2"
              />
              <Text fontWeight="extraBold" fontSize={1}>
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                  ml={1}
                >
                  Released {hasReleasedTickets} ticket(s)
                </Text>
              </Text>
            </Flex>
          </Box>
        )}
        {hasTransferPendingTickets > 0 && (
          <Box w="fit-content">
            <Flex
              borderRadius="full"
              bg="N200"
              p="1/2"
              pr={1}
              alignItems="center"
              justifyContent="center"
              mt="1/4"
            >
              <Flex
                borderRadius="full"
                width={16}
                height={16}
                bg="warning50"
                mr="1/2"
              />
              <Text fontWeight="extraBold" fontSize={1}>
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                  ml={1}
                >
                  {hasTransferPendingTickets} ticket(s) pending transfer
                </Text>
              </Text>
            </Flex>
          </Box>
        )}
        {hasTransferredTickets > 0 && (
          <Box w="fit-content">
            <Flex
              borderRadius="full"
              bg="N200"
              p="1/2"
              pr={1}
              alignItems="center"
              justifyContent="center"
              mt="1/4"
            >
              <Flex
                borderRadius="full"
                width={16}
                height={16}
                bg="warning50"
                mr="1/2"
              />
              <Text fontWeight="extraBold" fontSize={1}>
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                  ml={1}
                >
                  Transferred {hasTransferredTickets}
                  {numMembershipEvents
                    ? ` of ${numMembershipEvents} event`
                    : ""}{" "}
                  ticket(s)
                </Text>
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const shouldShowChangeSeats = (
  tickets: OrderQuery["order"]["tickets"],
  isNonSeated: boolean,
  order: OrderQuery["order"]
) =>
  order.status !== OrderStatus.Hold &&
  !!tickets?.length &&
  tickets.some(({ status }) => status === TicketStatus.Active) &&
  !isNonSeated;

const shouldShowRefundSeats = (order) => order.refundedAmount <= order.total;

export type TransactionFee = {
  transactionFeeType?: FeeType;
  transactionFee?: number;
  transactionFeeType2?: FeeType;
  transactionFee2?: number;
};

export const transactionFeeDescription = ({
  transactionFee,
  transactionFee2,
  transactionFeeType2,
  transactionFeeType,
}: TransactionFee) => {
  if (transactionFee2 && transactionFeeType2) {
    if (transactionFee) {
      if (transactionFeeType === FeeType.Percentage) {
        return ` (${transactionFee}% + $${transactionFee2.toFixed(2)})`;
      } else if (transactionFeeType === FeeType.FlatRate) {
        return ` (${transactionFee2}% + $${transactionFee.toFixed(2)})`;
      }
    } else if (transactionFeeType2 === FeeType.Percentage) {
      return ` (${transactionFee2}%)`;
    }
  }
  return transactionFeeType === FeeType.Percentage && transactionFee !== 0
    ? ` (${transactionFee}%)`
    : ``;
};

interface Props {
  order: OrderQuery["order"];
  mutate: typeof swrMutate;
  referralCampaign?: GetReferralCampaignByOrderIdQuery["getReferralCampaignByOrderId"];
  referralRewards?: GetReferralRewardByOrderIdQuery["getReferralRewardByOrderId"];
}

export const OrderDetails: FC<Props> = ({
  order,
  mutate,
  referralCampaign,
  referralRewards,
}) => {
  const { organization } = useOrganization();
  const { hasPermissions, Permission } = usePermissions();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showChangeSeatsModal, setShowChangeSeatsModal] = useState(false);
  const { XeroWarningModal, withXeroWarning } = useXeroWarningModal(order);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  //should be pulled into a central way of displaying what sort of reward someone has gotten
  //needs to cover points when implemented
  const referralDetails = referralRewards
    ? `Referral discount of ${referralCampaign?.referralOwnerRewardQuantity?.toFixed(
        2
      )}%`
    : undefined;

  const isNonSeated =
    (order.event
      ? order.event?.venue?.seating
      : order.membership
      ? order.membership?.venue?.seating
      : undefined) === VenueSeating.NonSeated;

  const isPointsOrder = order.orderType === OrderType.PointPurchase;
  const numMembershipEvents = order.membership?.events?.length;

  const lineItems = useMemo(
    () => order.lineItems?.edges?.map(({ node }) => node),
    [order.id, order.lineItems?.edges, order.total]
  );

  const { activePromotions: seatedPromotions } = useSeatedMultibuy(
    order.event?.multiBuyPromotions || order.membership?.multiBuyPromotions,
    lineItems
  );
  const { activePromotions: promotions } = useMultiBuy(
    order.event?.multiBuyPromotions || order.membership?.multiBuyPromotions,
    lineItems
  );

  const activePromotions =
    (order.event
      ? order.event?.venue?.seating
      : order.membership
      ? order.membership?.venue?.seating
      : undefined) === VenueSeating.NonSeated
      ? promotions
      : seatedPromotions;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const discountAmount = calculateDiscount(
    order.promoCode,
    lineItems as LineItem[], // TODO really need to fix this stuff...
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    activePromotions
  );

  const { data: transferredMembershipTicketsData } = useQuery(
    TransferredMembershipTicketsForOrderDocument,
    {
      id: order.id,
    }
  );

  useEffect(() => {
    if (!isEditing && selectedItems.length) setSelectedItems([]);
  }, [isEditing]);

  const selectItem = (item) =>
    item
      ? setSelectedItems([
          ...selectedItems,
          { id: item.id, price: item.price, quantity: item?.quantity ?? 1 },
        ])
      : null;

  const unselectItem = (id) =>
    setSelectedItems(selectedItems?.filter((item) => item.id !== id));

  const toggleItem = (isSelected, item) =>
    isSelected ? selectItem(item) : unselectItem(item?.id);

  const tickets = order.tickets?.filter(
    ({ status }) => status === TicketStatus.Active
  );

  const transferredTickets = order.tickets?.filter(
    (t) => t.status === TicketStatus.Transferred
  );

  const transferredTicketsCount =
    (transferredTickets?.length ?? 0) +
    (transferredMembershipTicketsData?.transferredMembershipTicketsForOrder
      ?.length ?? 0);

  const totalSellerTransferFees = (() =>
    (transferredTickets?.reduce(
      (acc, t) =>
        acc +
        (t.transferFee?.type === TransferFeeType.Seller
          ? t.transferFee?.fee ?? 0
          : 0),
      0
    ) ?? 0) +
    (transferredMembershipTicketsData?.transferredMembershipTicketsForOrder?.reduce(
      (acc, t) =>
        acc +
        (t.transferFee?.type === TransferFeeType.Seller
          ? t.transferFee?.fee ?? 0
          : 0),
      0
    ) ?? 0))();

  const countReleasedTickets = (item: typeof lineItems[0]) =>
    order?.tickets?.filter(
      (ticket) =>
        ticket.lineItem.id === item?.id &&
        ![
          TicketStatus.Active,
          TicketStatus.Transferred,
          TicketStatus.TransferPending,
        ].includes(ticket.status)
    ).length;

  const countTransferredTickets = (item: typeof lineItems[0]) =>
    (order?.tickets?.filter(
      (ticket) =>
        ticket.lineItem.id === item?.id &&
        ticket.status === TicketStatus.Transferred
    ).length ?? 0) +
    (transferredMembershipTicketsData?.transferredMembershipTicketsForOrder?.filter(
      (t) => t.lineItem?.id === item?.id
    ).length ?? 0);

  const countTransferPendingTickets = (item: typeof lineItems[0]) =>
    order?.tickets?.filter(
      (ticket) =>
        ticket.lineItem.id === item?.id &&
        ticket.status === TicketStatus.TransferPending
    ).length;

  const countActiveTickets = (order: OrderQuery["order"]) =>
    order?.tickets?.filter((ticket) =>
      [TicketStatus.Active, TicketStatus.TransferPending].includes(
        ticket.status
      )
    ).length;

  return (
    <>
      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        <Title>Product</Title>
        <Title>Qty</Title>
        <Title>Tax</Title>
        <Title>Total</Title>

        {orderBy(lineItems, ["type", "name"], ["desc", "asc"]).map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const isMultiBuy = activePromotions.find(
            (promo) =>
              promo.id === item.id ||
              (item?.ticketType?.id || item?.membershipType?.id) ===
                promo?.getType?.id
          );

          const promo = isMultiBuy &&
            (isMultiBuy.type === "getting" || isMultiBuy.giftedQty > 0) && (
              <Text ml="1/4">
                Multibuy promotion applied, you saved{" "}
                <Price
                  price={item.originalPrice * (isMultiBuy?.giftedQty ?? 1)}
                />
              </Text>
            );

          return (
            <React.Fragment key={item.id}>
              <OrderItem
                title={`${item.name} ${
                  item.seatZone
                    ? " - " +
                      formatSeatLocation(
                        item.seatZone,
                        item.seatSection,
                        item.seatLabel
                      )
                    : ""
                }`}
                promo={promo}
                isEditing={isEditing}
                numReleasedTickets={countReleasedTickets(item)}
                numTransferredTickets={countTransferredTickets(item)}
                numTransferPendingTickets={countTransferPendingTickets(item)}
                numMembershipEvents={numMembershipEvents}
                onChange={(e) => toggleItem(e.target.checked, item)}
                referral={referralDetails}
              />
              <Text>{item.quantity?.toLocaleString()}</Text>
              <Text>
                {" "}
                {organization.address?.country === "NZ"
                  ? "15%"
                  : organization.address?.country === "AU"
                  ? "10%"
                  : "All relevant taxes apply"}
              </Text>
              <Flex flexDir="column">
                {isMultiBuy &&
                (isMultiBuy.type === "getting" || isMultiBuy.giftedQty > 0) ? (
                  item.originalPrice * (isMultiBuy?.giftedQty ?? 1) ===
                  item.originalPrice * item.quantity ? (
                    <Text>Free</Text>
                  ) : (
                    <>
                      <Text>
                        {formatPrice(item.originalPrice * item.quantity)}
                      </Text>
                      <Price
                        fontSize={1}
                        lineHeight="normal"
                        price={
                          -(item.originalPrice * (isMultiBuy.giftedQty ?? 1))
                        }
                        color="success"
                      />
                    </>
                  )
                ) : (
                  <>
                    <Text>
                      {formatPrice(item.originalPrice * item.quantity)}
                    </Text>
                    {item.originalPrice !== item.price && (
                      <Price
                        fontSize={1}
                        lineHeight="normal"
                        price={
                          -((item.originalPrice - item.price) * item.quantity)
                        }
                        color="success"
                      />
                    )}
                  </>
                )}
              </Flex>
            </React.Fragment>
          );
        })}
      </Box>

      <Divider my={2} />

      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        <Text>
          Total number of{" "}
          {isPointsOrder
            ? getPointsDisplayLabel(organization.point?.name)
            : order.event?.title
            ? `tickets`
            : `memberships`}
        </Text>
        <Box gridColumn="2">
          <Text whiteSpace="nowrap">
            {isPointsOrder
              ? getPointsQuantityFromOrder(order)
              : countActiveTickets(order)}
          </Text>
        </Box>
      </Box>

      <Divider my={2} />

      {order.promoCode && (
        <>
          <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
            <Box>
              <Text>Promo code applied</Text>
              <Text
                fontSize={1}
                lineHeight="normal"
                fontWeight="demiBold"
                color="N600"
              >
                {`"${order.promoCode.code}" ${
                  order.promoCode.discountType === DiscountType.Fixed ? "$" : ""
                }${order.promoCode.discountAmount.toFixed(2)}${
                  order.promoCode.discountType === DiscountType.Percentage
                    ? "%"
                    : ""
                } discount code`}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" gridColumn="4">
              <Price ml={"-8px" as any} price={-discountAmount} />
            </Box>
          </Box>
          <Divider my={2} />
        </>
      )}

      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        <Text>
          Delivery costs{" "}
          {order.deliveryMethod
            ? `(${formatDeliveryMethod(order.deliveryMethod)})`
            : null}
        </Text>
        <OrderItem
          isEditing={isEditing}
          onChange={(e) =>
            toggleItem(e.target.checked, {
              id: "shipping-fee",
              price: order.deliveryFee || 0,
            })
          }
        />
        <Box gridColumn="4">
          <Text whiteSpace="nowrap">
            {!order.deliveryFee
              ? order.deliveryFee === 0
                ? "Free shipping"
                : "-"
              : `${formatPrice(order.deliveryFee)}`}
          </Text>
        </Box>
      </Box>

      <Divider my={2} />

      {!isPointsOrder && (
        <TicketFees
          lineItems={lineItems?.filter(
            ({ type }) => type !== LineItemType.Addon
          )}
          isEditing={isEditing}
          toggleItem={(e) =>
            toggleItem(e.target.checked, {
              id: "ticket-fees",
              price:
                lineItems.reduce(
                  (acc, curr) =>
                    acc + curr.ticketFeeAmount * (curr?.quantity ?? 1),
                  0
                ) || 0,
            })
          }
        />
      )}

      {order.orderTicketCover && (
        <TicketCover orderTicketCover={order.orderTicketCover} />
      )}

      {!isPointsOrder && (
        <>
          {" "}
          <Box
            display="grid"
            gridTemplateColumns="9fr 2fr 2fr 1fr"
            gridGap={3}
            my={1}
          >
            <Text>Order Handling Fee</Text>
            <OrderItem
              isEditing={isEditing}
              onChange={(e) =>
                toggleItem(e.target.checked, {
                  id: "booking-fee",
                  price: order.bookingFeeAmount || 0,
                })
              }
            />

            <Box gridColumn="4">
              <Text>{formatPrice(order.bookingFeeAmount)}</Text>
            </Box>
            <Text>Transaction fee {transactionFeeDescription(order)}</Text>
            <OrderItem
              isEditing={isEditing}
              onChange={(e) =>
                toggleItem(e.target.checked, {
                  id: "transaction-fee",
                  price: order.transactionFeeAmount || 0,
                })
              }
            />
            <Box gridColumn="4">
              <Text>{formatPrice(order.transactionFeeAmount || 0)}</Text>
            </Box>

            {!!order.transferFee && order.transferFee > 0 && (
              <>
                <Text>Ticket transfer fee</Text>
                <OrderItem
                  isEditing={isEditing}
                  onChange={(e) =>
                    toggleItem(e.target.checked, {
                      id: "transfer-fee",
                      price: order.transferFee || 0,
                    })
                  }
                />
                <Box gridColumn="4">
                  <Text>{formatPrice(order.transferFee)}</Text>
                </Box>
              </>
            )}
          </Box>
          <Divider my={2} />
        </>
      )}

      {order.usedCredits > 0 && (
        <>
          <Box display="grid" gridTemplateColumns="13fr 1fr">
            <Text>Account credits</Text>
            <Flex alignItems="center" justifyContent="flex-start">
              <Price price={-order.usedCredits} />
            </Flex>
          </Box>
          <Divider my={2} />
        </>
      )}

      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        {[OrderStatus.Completed, OrderStatus.Paid, OrderStatus.Hold].includes(
          order.status
        ) && (
          <Flex alignItems="center">
            {!isEditing && (
              <>
                {shouldShowChangeSeats(tickets, isNonSeated, order) && (
                  <SecondaryButton
                    type="button"
                    onClick={withXeroWarning(() =>
                      setShowChangeSeatsModal(true)
                    )}
                  >
                    Change seats
                  </SecondaryButton>
                )}

                {!isPointsOrder &&
                  hasPermissions(Permission.OrderRefund) &&
                  shouldShowRefundSeats(order) && (
                    <SecondaryButton
                      fontSize={2}
                      type="button"
                      onClick={withXeroWarning(() => setShowRefundModal(true))}
                      ml={
                        shouldShowChangeSeats(tickets, isNonSeated, order)
                          ? 2
                          : 0
                      }
                    >
                      Refund / Release seats
                    </SecondaryButton>
                  )}

                {hasPermissions(Permission.OrderCreate) &&
                  order.status === OrderStatus.Hold && (
                    <SecondaryButton
                      fontSize={2}
                      type="button"
                      onClick={withXeroWarning(() => setShowEditModal(true))}
                      ml={
                        hasPermissions(Permission.OrderRefund) &&
                        shouldShowRefundSeats(order)
                          ? 2
                          : 0
                      }
                    >
                      Edit
                    </SecondaryButton>
                  )}

                {hasPermissions(Permission.OrderCreate) &&
                  order.status === OrderStatus.Hold &&
                  order.lineItems?.edges?.length > 1 && (
                    <SecondaryButton
                      fontSize={2}
                      type="button"
                      onClick={() => setShowTransferModal(true)}
                      ml={
                        hasPermissions(Permission.OrderCreate) &&
                        order.status === OrderStatus.Hold
                          ? 2
                          : 0
                      }
                    >
                      Split
                    </SecondaryButton>
                  )}
              </>
            )}
          </Flex>
        )}

        <Box gridColumn="2 / span 2">
          <Title mb={2}>
            {organization.address?.country === "NZ"
              ? "Sub-total (excl 15% GST)"
              : organization.address?.country === "AU"
              ? "Sub-total (excl 10% GST)"
              : "All relevant taxes apply"}
          </Title>

          <Title>Order total</Title>
        </Box>
        <Box>
          <Text mb={2}>
            {organization.address?.country === "NZ"
              ? formatPrice(order.subtotal)
              : "-"}
          </Text>
          <Text>{formatPrice(order.total)}</Text>
        </Box>
      </Box>

      <Divider my={2} />

      {order.refundedAmount > 0 && (
        <>
          <Box display="grid" gridTemplateColumns="13fr 1fr" gridGap={3}>
            <Flex flexDir={"column"}>
              <Text>Refunded amount</Text>
              {transferredTicketsCount > 0 && (
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                >
                  Includes ticket transfer fee of{" "}
                  {formatPrice(totalSellerTransferFees)}
                </Text>
              )}
            </Flex>
            <Flex alignItems="center" justifyContent="flex-start">
              <Price price={-order.refundedAmount} />
            </Flex>
          </Box>
          <Divider my={2} />
          <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
            <Box gridColumn="2">
              <Title>Adjusted total</Title>
            </Box>
            <Box gridColumn="4">
              <Text>
                {formatPrice(order.total - (order.refundedAmount ?? 0))}
              </Text>
            </Box>
          </Box>
          <Divider my={2} />
        </>
      )}

      <Modal
        isOpen={showChangeSeatsModal}
        close={() => setShowChangeSeatsModal(false)}
        header="Change Seats"
      >
        <ChangeSeatsModalContent
          order={order}
          orgSlug={organization?.slug}
          close={() => setShowChangeSeatsModal(false)}
        />
      </Modal>

      <Modal
        isOpen={showRefundModal}
        close={() => setShowRefundModal(false)}
        header="Refund order"
      >
        <RefundsModalContent
          order={order}
          mutate={mutate}
          setIsOpen={setShowRefundModal}
        />
      </Modal>

      <Modal
        isOpen={showEditModal}
        close={() => setShowEditModal(false)}
        header="Edit order"
      >
        <EditModalContent
          order={order}
          mutate={mutate}
          setIsOpen={setShowEditModal}
        />
      </Modal>

      <TransferModal
        order={order}
        mutate={mutate}
        isOpen={showTransferModal}
        setIsOpen={setShowTransferModal}
      />
      {/* The Xero warning modal needs to come last so it renders on top of all other modals */}
      <XeroWarningModal />
    </>
  );
};
